<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'8'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>留言管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>留言列表</span>
				</div>
				<!-- <div class="left-list-item" @click="leftClick('1')">
					<span>知识列表</span>
				</div> -->
			</div>
			<div class="right-next">
				<div class="title">
					<span>留言列表</span>
				</div>
				<div class="date-search">
					<el-input placeholder="留言标题搜索" v-model="searchValue"></el-input>
					<el-button type="primary" icon="el-icon-search" @click="searSubmit">搜索</el-button>
				</div>
				<div class="mt-20">
					<el-table :data="tableData.data" border style="width: 100%">
						<el-table-column prop="id" label="ID" width="120" align="center">
						</el-table-column>
						<el-table-column prop="title" label="留言标题" width="250" align="center">
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="scope">
								<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">详情
								</el-button>
								<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="bottom-paging">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="1" :page-sizes="[5, 10, 20, 30, 100]" :page-size="num"
						layout="total, prev, pager, next, sizes" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				tableData: [],
				isCollapse: false,
				page: 1,
				num: 10,
				searchValue:''
			};
		},
		created() {
			this.getArticlelist();
		},
		methods: {
			searSubmit(){
				this.page = 1;
				this.getArticlelist();
			},
			getArticlelist() {
				this.$get("comment/get_list", {
					page: this.page,
					limit: this.num,
					name:this.searchValue,
					is_page:1,
				}).then((res) => {
					console.log(res);
					for (let i = 0; i < res.data.data.data.length; i++) {
						if (res.data.data.data[i].mining_img != null) {
							res.data.data.data[i].mining_img = this.$store.state.imaUrl + res.data.data.data[i].mining_img;
						}
					}
					this.tableData = res.data.data;
					// console.log(res);
				});
			},

			leftClick(index) {
				if (index == 0 && this.$route.path != "/message") {
					this.$router.push("/message");
				}else {
					this.reload();
				}
			},
			handleEdit(index, row) {
				console.log(index, row);
				this.$router.push({
					query: {
						id: row.id
					},
					name: 'messageinfo'
				});
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.num = val;
				this.getArticlelist();
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val;
				this.getArticlelist();
			},

			handleDelete(index, row) {
				this.$confirm("此操作做将会永久删除此条留言，是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
						center: true,
					})
					.then(() => {
						this.$post("del/comment", {
							id: row.id,
						}).then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.getArticlelist();
							} else {
								this.$notify({
									title: "提示",
									message: res.data.message,
									type: "error",
								});
							}
						});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除",
						});
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-search {
			margin-top: 30px;
			.el-input{
				width: 300px;
				margin-right: 20px;
			}
			.el-button {
				padding: 12px 20px;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
